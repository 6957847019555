<template>
  <input ref="inputRef" v-model="internalValue" type="text" />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import moeda from "../modules/moeda";

export default {
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);
    let internalValue = '0,00';

    return { inputRef, internalValue };
  },
  props: ['value', 'options'],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    value: function(newVal) {
      if (newVal || newVal === 0) {
        this.internalValue = moeda.toStringBrl(newVal);
      }
    },
  },
};
</script>
